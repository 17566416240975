<template>
  <div>
    <v-text-field
        v-if="type==='TEXT' || type==='INTEGER' || type==='DECIMAL'"
        :label="year == null ? 'Текущие данные' : 'Данные за ' + year + ' год'"
        :value="value"
        @change="validateAndChange($event)"
    ></v-text-field>
    <v-select
        v-if="type==='OPTIONAL'"
        :label="year == null ? 'Текущие данные' : 'Данные за ' + year + ' год'"
        :value="value == null ? null : +value"
        :items="options"
        item-text="value"
        item-value="id"
        @change="validateAndChange($event)"
    ></v-select>
    <span v-if="errorText" style="color: red">{{ errorText }}</span>
  </div>
</template>

<script>
export default {
  name: "PassportIndicatorEditBox",
  props: ["type", "year", "value", "options", "departmentName"],
  data: function () {
    return {
      errorText: null
    };
  },
  methods: {
    validateAndChange(value) {
      let valstat = this.isValid(value)

      if (valstat === true) {
        this.errorText = null
      } else {
        this.errorText = valstat
      }
      this.$emit('change', this.year === 'null' ? null : this.year, value, this.departmentName)
    },

    isValid(value) {
      if(value == null || value == "")
        return true
      if (this.type === 'DECIMAL') {
        if (!this.isNumeric(value)) {
          return "Некорректное число"
        }
      } else if (this.type === 'INTEGER') {
        if (!this.isNormalInteger(value)) {
          return "Некорректное целое число"
        }
      } else if (this.type === 'TEXT') {
        if (value.length > 4000) {
          return "Длина текста не может превышать 4000 символов"
        }
      }
      return true
    },

    isNormalInteger(str) {
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },

    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }
  },
  beforeMount() {
    // console.log(this.options)
  }
}
</script>

<style scoped>

</style>