<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1 style="padding-right: 60px">
            Заполнение данных по всем ЦЗН региона
            <span v-if="($user.role==='ROLE_CZN_MANAGER'
                        || $user.role==='ROLE_CZN_TERRITORIAL'
                        || $user.role==='ROLE_REGIONAL_AGENCY')" class="subheader">
              {{userRegion.name}}</span>
            <v-autocomplete
                v-else
                style="width: 500px; margin-top: 10px"
                dense
                label="Регион"
                :items="regionList"
                item-text="name"
                return-object
                v-model="userRegion"
                @input="initialize()"
            ></v-autocomplete>

            <div class="download-menu">
              <v-dialog
                  v-model="passportCZNExportDialog"
                  max-width="600px"
                  persistent>
                <template v-slot:activator="{ on }">
                  <div class="download-menu-icon" v-on="on">
                    <svg
                        fill="none"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                          fill="#0033A0"
                      />
                    </svg>
                  </div>
                </template>

                <PassportCZNExportDialog
                    @close="passportCZNExportDialog = false; exportDialogKey++"
                    :is-from-config="true"
                    :key="exportDialogKey"/>
              </v-dialog>
            </div>
          </h1>

          <div class="list passport">
            <div
                v-for="section in config.sections"
                :key="'liw' + section.id"
                class="list-item-wrap"
            >
              <v-expansion-panels>
                <v-expansion-panel :key="'vep' + section.id">
                  <v-expansion-panel-header>
                    <div class="list-item">
                      <a class="list-item-name">
                        <button class="toggleSwitch">
                          <svg
                              class="closed"
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z"
                                  fill="#1551D0"/>
                          </svg>
                          <svg
                              class="opened"
                              fill="none"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                              xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z"
                                  fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>{{ section.id }}. {{ section.name }}</span>
                      </a>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <table>
                      <thead>
                        <tr>
                          <th colspan="2">Показатели (данные)</th>
                        </tr>
                      </thead>
                      <tbody>
                      <template v-for="indicator in section.indicators">
                        <tr :key="departments.id + showYear + section.id + '.' + indicator.id">
                          <td>{{ section.id }}.{{ indicator.id }}</td>
                          <td>{{ indicator.name }}</td>
                          <td>
                            <PassportCZNMassEditDialog
                                :configElement="getConfigElement(section.id, indicator.id, null)"
                                :config-id="config.id"
                                :section-id="section.id"
                                :indicator-id="indicator.id"
                                :sub-indicator-id="null"
                                :region-code="userRegion.code"
                                :departments="departments"
                                :values="filterValues(section.id, indicator.id, null)"
                                :key="editDialogKey"
                                @updated="initialize()"
                            />
                          </td>
                        </tr>
                        <tr v-for="subIndicator in indicator.subIndicators"
                            :key="departments.id + showYear + section.id + '.' + indicator.id + '.' + subIndicator.id">
                          <td>
                            {{ section.id }}.{{ indicator.id }}.{{ subIndicator.id }}
                          </td>
                          <td>{{ subIndicator.name }}</td>
                          <td>
                            <PassportCZNMassEditDialog
                                :configElement="getConfigElement(section.id, indicator.id, subIndicator.id)"
                                :config-id="config.id"
                                :section-id="section.id"
                                :indicator-id="indicator.id"
                                :sub-indicator-id="subIndicator.id"
                                :region-code="userRegion.code"
                                :departments="departments"
                                :values="filterValues(section.id, indicator.id, subIndicator.id)"
                                :key="editDialogKey"
                                @updated="initialize()"
                            />
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>

        <div
            v-if="errorText != null"
            class="error"
            style="position: fixed; bottom: 0"
        >
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height: 1000px"></div>
    </transition>
  </div>
</template>

<script>
import PassportCZNMassEditDialog from "@/components/dialogs/PassportCZNMassEditDialog";
import PassportCZNExportDialog from "@/components/dialogs/PassportCZNExportDialog";

export default {
  name: "PassportCZNMassEdit",
  components: {
    PassportCZNExportDialog,
    PassportCZNMassEditDialog,
  },

  data() {
    return {
      config: null,
      values: null,
      indicatorsEditInfo: null,
      allIndicatorsEditInfoMap: new Map(),
      apiLoaded: false,
      userRegion: {},
      departments: [],
      regionList: [],
      errorText: null,
      showYear: null,
      dialog: false,
      editDialogKey: 0,
      exportDialogKey: 0,
      passportCZNExportDialog: false,
      allValuesMap: new Map(),
    };
  },

  methods: {
    async loadConfig() {
      let req = await this.$getApi("/passportCzn/getActiveConfig");
      if (req.ok) {
        this.config = req.payload;
        this.config.sections = JSON.parse(this.config.sectionsJson);
        // console.log('this.config', this.config)
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    filterValues(sectionId, indicatorId, subIndicatorId) {
      let fValues = new Map();
      for (let dep of this.departments) {
        let value = this.allValuesMap.get(dep.name).filter(
            v => v.sectionId === sectionId
                 && v.indicatorId === indicatorId
                 && v.subIndicatorId === subIndicatorId);
        fValues.set(dep.name, value);
      }
      // console.log('fValues', Object.fromEntries(fValues.entries()))
      return fValues
    },

    getConfigElement(sectionId, indicatorId, subIndicatorId) {
      for (let section of this.config.sections) {
        if (section.id === sectionId) {
          for (let indicator of section.indicators) {
            if (indicator.id === indicatorId) {
              if (subIndicatorId == null) {
                return indicator;
              }
              for (let subIndicator of indicator.subIndicators) {
                if (subIndicator.id === subIndicatorId) return subIndicator;
              }
            }
          }
        }
      }
    },

    async loadAllValues() {
      let requests = this.departments.map(dep => this.loadValues(dep.name)),
          allValues = await Promise.all(requests);
      allValues.forEach((v, index) => this.allValuesMap.set(this.departments[index].name, v.payload));
      // console.log('this.allValuesMap', Object.fromEntries(this.allValuesMap.entries()));
    },

    loadValues(cznName) {
      return this.$getApi("/passportCzn/getValues", {
        region: this.userRegion.code,
        czn: cznName,
        configId: this.config.id,
      })
    },

    async initialize() {
      // console.log('this.userRegion', this.userRegion)
      this.departments = this.regionList.find(reg => reg.code === this.userRegion.code).departments;
      // console.log('this.departments', this.departments)
      await this.loadAllValues();
      this.forceRerender();
    },

    forceRerender() {
      this.editDialogKey += 1;
      // this.exportDialogKey += 1;
    },
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    this.regionList = this.$cznDepartmentsByRegionList;
    await this.loadConfig();

    if (this.$user.role === 'ROLE_CZN_MANAGER' || this.$user.role === 'ROLE_REGIONAL_AGENCY') {
      this.userRegion = this.$user.region;
    } else if (this.$user.role === 'ROLE_ADMIN' || this.$user.role === 'ROLE_VNII') {
      this.userRegion = this.regionList[0];
    } else { //для территориальных, чтобы не отрисовывалась страница (они и не должны попасть на неё)
      this.userRegion = null
    }

    await this.initialize();
    this.apiLoaded = true;
  },
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style scoped lang="scss">
.download-menu {
  top: 20px;
}

.additional-info {
  div {
    display: inline-block;
    min-width: 65px;
    font-size: 13px;
    font-weight: 500;
    color: #777779;
  }
  .v-icon {
    display: inline-block;
    margin-left: 5px;
    font-size: 14px;
    color: #777779;
  }
  a {
    font-size: 12px;
  }
}

.delbtn {
  // position: absolute;
  // top: 10px;
  // right: 10px;
  margin-left: 100px;
  .v-icon {
    font-size: 16px;
    color: #0033A0;
  }
}

.subheader {
  font-size: 17px;
  color: #1C1C1F;
  font-weight: 600;
}
</style>