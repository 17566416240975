<template>
  <v-dialog v-model="dialog" max-width="1200px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs" v-on="on" href="#" @click.prevent>
        <div class="edit">
          <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.13 12L19.39 10.74C19.83 10.3 20.39 10.06 21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H11V19.13L11.13 19H5V5H12V12H18.13ZM14 4.5L19.5 10H14V4.5ZM19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83ZM22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19Z"
                  fill="#0033A0"/>
          </svg>
        </div>
      </a>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">
          {{ sectionId }}.{{ indicatorId }}{{ subIndicatorId ? `.${subIndicatorId}` : ''}}  {{configElement.name}}
        </span>
        <a class="close" @click="dialog = false">
          <v-icon style="font-size: 24px; color: #0033A0">mdi-window-close</v-icon>
        </a>
      </v-card-title>
      <v-card-text>
        <div class="list">
          <table>
            <thead>
              <tr>
                <th>ЦЗН</th>
                <th>Данные</th>
                <template v-if="configElement.yearly">
                  <th></th>
                  <th></th>
                </template>
                <th>Дата актуальности</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(dep, depIndex) of departments" :key="'dep'+depIndex">
                <td style="width: 380px">{{ depIndex + 1 }}. {{ dep.name }}</td>
                <td>
                  <PassportCZNIndicatorEditBox
                      :value="getValue(dep.name, null)"
                      :year="null"
                      :type="configElement.dataType"
                      :options="configElement.options"
                      :departmentName="dep.name"
                      @change="setValue"
                  ></PassportCZNIndicatorEditBox>
                </td>
                <template v-if="configElement.yearly">
                  <td>
                    <PassportCZNIndicatorEditBox
                        :value="getValue(dep.name, 2022)"
                        :year="2022"
                        :type="configElement.dataType"
                        :options="configElement.options"
                        :departmentName="dep.name"
                        @change="setValue"
                    ></PassportCZNIndicatorEditBox>
                  </td>
                  <td>
                    <PassportCZNIndicatorEditBox
                        :value="getValue(dep.name, 2021)"
                        :year="2021"
                        :type="configElement.dataType"
                        :options="configElement.options"
                        :departmentName="dep.name"
                        @change="setValue"
                    ></PassportCZNIndicatorEditBox>
                  </td>
                </template>
                <td>
                  <input type="date"
                         :max="maxDate"
                         placeholder="Дата актуальности данных"
                         v-model="query.get(dep.name).relevanceDate"
                         style="font-size: 16px"
                         @change="setRelevanceDate(query.get(dep.name).relevanceDate, dep.name)">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="btn btn-outline"
            text
            @click="dialog=false"
        >
          Закрыть
        </v-btn>
        <v-btn
            class="btn btn-primary"
            :disabled="submitButtonDisabled || !isValidValuesList()"
            @click="submit()"
        >
          Сохранить изменения
        </v-btn>
      </v-card-actions>
      <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
        Ошибка: {{ errorText }}
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PassportCZNIndicatorEditBox from "@/components/elements/PassportCZNIndicatorEditBox";

export default {
  name: "PassportCZNMassEditDialog",
  components: {PassportCZNIndicatorEditBox},
  props: ['configElement', 'configId', 'regionCode', 'sectionId', 'indicatorId', 'subIndicatorId', 'departments', 'values'],

  data: () => ({
    dialog: false,
    submitButtonDisabled: false,
    query: new Map(),
    todayDate: new Date().toISOString().split('T')[0],
    maxDate: new Date(),
    errorText: null,
  }),

  methods: {
    initialize() {
      // console.log('departments', this.departments);
      // console.log('this.values', Object.fromEntries(this.values.entries()))
      this.formInitialData()
    },

    formInitialData() {
      for (let dep of this.departments) {
        this.query.set(dep.name, {
          region: this.regionCode,
          configId: this.configId,
          sectionId: this.sectionId,
          indicatorId: this.indicatorId,
          subIndicatorId: this.subIndicatorId,
          passportCznValues: this.values.get(dep.name),
          czn: dep.name,
          relevanceDate: this.getRelevanceDate(this.values.get(dep.name))
        })
      }
      // console.log('this.query', Object.fromEntries(this.query.entries()))
    },

    getRelevanceDate(valueArray) {
      if (valueArray.length !== 0) {
        //дата актуальности одинакова для значений индикатора/субиндикатора по разным годам,
        //поэтому берется у первого
        return valueArray[0].relevanceDate
      } else {
        return this.todayDate
      }
    },

    getValue(depName, year) {
      for (let v of this.query.get(depName).passportCznValues) {
        if (v.year === year)
          return v.value
      }
      return null
    },

    setMaxMinDate() {
      this.maxDate.setDate(this.maxDate.getDate() + 7);
      this.maxDate = this.maxDate.toISOString().split('T')[0];
    },

    setValue(year, value, depName) {
      this.deleteValue(year, depName);
      if (value != null && value !== '') {
        this.query.get(depName).passportCznValues.push({
          year: year,
          region: this.regionCode,
          configId: this.configId,
          sectionId: this.sectionId,
          indicatorId: this.indicatorId,
          subIndicatorId: this.subIndicatorId,
          czn: depName,
          relevanceDate: this.getRelevanceDate(this.values.get(depName)),
          value: value
        })
      }
    },

    deleteValue(year, depName) {
      let arr = this.query.get(depName).passportCznValues;
      for (let i = 0; i < arr.length; i++){
        if(arr[i].year === year){
          arr.splice(i, 1)
        }
      }
    },

    setRelevanceDate(relevanceDate, depName) {
      for (let passportValue of this.query.get(depName).passportCznValues) {
        passportValue.relevanceDate = relevanceDate;
      }
    },

    async submit() {
      if (!this.isValidValuesList()) {
        return
      }

      this.submitButtonDisabled = true
      // console.log("submit query", Array.from(this.query.values()))
      let req = await this.$postApi("/passportCzn/saveValuesForRegion", Array.from(this.query.values()))
      if (req.ok) {
        this.dialog = false
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        this.submitButtonDisabled = false
        return
      }

      this.submitButtonDisabled = false
      this.$emit('updated')
    },

    isValidValuesList() {
      for (let dep of this.departments) {
        for (let v of this.query.get(dep.name).passportCznValues) {
          if (this.isValidValue(v.value) !== true)
            return false
        }
      }
      return true
    },

    isValidValue(value) {
      if(value == null || value == "")
        return true
      if (this.configElement.dataType === 'DECIMAL') {
        if (!this.isNumeric(value)) {
          return "Некорректное число"
        }
      } else if (this.configElement.dataType === 'INTEGER') {
        if (!this.isNormalInteger(value)) {
          return "Некорректное целое число"
        }
      } else if (this.configElement.dataType === 'TEXT') {
        if (value.length > 4000) {
          return "Длина текста не может превышать 4000 символов"
        }
      }
      return true
    },

    isNormalInteger(str) {
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    },

    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }
  },

  filters: {
    dateTimeFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    },

    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  beforeMount() {
    this.setMaxMinDate();
    this.initialize();
  }
}
</script>

<style scoped>
table th {
    border-bottom: 1px solid #E8E8E9;
  }

.v-dialog .v-card__actions .btn {
  width: auto !important;
}
</style>